import { css, CSSObject, SimpleInterpolation } from 'styled-components';

export const base = '16px';
export const marginTop = '40px';
const spacer = '1rem';

export default spacer;

// Converts PX units to REM units
export function rem(sizes: string): string {
  return sizes
    .split(' ')
    .map((u) => `${parseFloat(u) / parseFloat(base)}rem`)
    .join(' ');
}

export const spacers = {
  base: spacer,
  sm: '0.625rem',
  md: '1.5rem',
  lg: '2rem',
  xl: '3rem',
  xxl: '4rem',
};

export const sm = '0.625rem';
export const md = '1.5rem';
export const lg = '2rem';
export const xl = '3rem';
export const xxl = '4rem';

export const borderRadius = '0.5rem';

export const breakpoint = {
  sm: rem('576px'),
  md: rem('768px'),
  lg: rem('992px'),
  xl: rem('1200px'),
};

export function grid(columns: string): { gap: string; colSize: string } {
  const parsedColumns = parseFloat(columns) || 12;

  const gap = 1;
  const colSize =
    (parseFloat(breakpoint.xl) - gap * parsedColumns) / parsedColumns;

  return {
    gap: `${gap}rem`,
    colSize: `${colSize}rem`,
  };
}

function createMediaFunction(minWidth: string) {
  return (
    first: TemplateStringsArray | CSSObject,
    ...interpolations: SimpleInterpolation[]
  ) => css`
    @media (min-width: ${minWidth}) {
      ${css(first, ...interpolations)};
    }
  `;
}

// Iterate through the sizes and create a media template
export const media = {
  sm: createMediaFunction(breakpoint.sm),
  md: createMediaFunction(breakpoint.md),
  lg: createMediaFunction(breakpoint.lg),
  xl: createMediaFunction(breakpoint.xl),
};
