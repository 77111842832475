import { ThemeProps } from 'styled-components';

export const gray = '#b2b2b2';
export const grayDark = '#898989';
export const grayLight = '#e6e6e6';
export const black = '#000';
export const subnavGray = '#f3f1f5';
export const green = '#18a9b3';
export const greenHover = '#127F86';
export const purple = '#764b9a';
export const pink = '#ff90a3';
export const lightPink = '#f7c1ca';
export const veryLightPink = '#fcecef';
export const white = '#fff';
export const lightBlue = '#60c4ec';
export const blue = '#1D73B9';
export const red = '#DC425B';
export const transparent = 'transparent';

export const backgroundColor = '#faf7fb';
export const formFieldBackground = '#f1eff4';
export const headerBackground = white;
export const required = '#fd91a4';
export const disabledLabel = purple;
export const disabledValue = '#000';
export const inherit = 'inherit';

export const getColor = <R extends Record<string, any>>(
  key: string,
  fallback: string
) => (props: ThemeProps<any> & R): string => {
  const proposedColor = props[key];

  // if color is something like #fff, we want to use that instead of looking up that string on the color theme
  if (proposedColor && proposedColor[0] === '#') {
    return proposedColor;
  }

  return props.theme.colors[proposedColor] || props.theme.colors[fallback];
};
