// @flow
import { ApolloClient } from 'apollo-client';
import { ApolloLink, Observable } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry';
import { onError as apolloLinkError } from 'apollo-link-error';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { print } from 'graphql';
import { getToken, deleteToken, logout } from './auth';
import Router from 'next/router';
import { apiGraphqlErrorTrap, apiNetworkErrorTrap } from '../helpers/sentry';

const handleLogout = () => {
  if (process.browser) {
    if (!(window.location.pathname === '/signup')) {
      deleteToken();
      logout();
      Router.replace('/login');
    }
  }
};

export default function createApolloClient(initialState, ctx) {
  const onError = apolloLinkError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        console.error('GraphQL Error', JSON.stringify(graphQLErrors, null, 4));
        console.error('GraphQL Query', print(operation.query));
        console.error(
          'GraphQL Variables',
          JSON.stringify(operation.variables || {}, null, 4)
        );
        // eslint-disable-next-line no-unused-vars
        for (let err of graphQLErrors) {
          if (err.extensions?.category === 'forbidden') {
            handleLogout();
          }
          switch (err.category) {
            case 'forbidden':
              handleLogout();
              break;
            default:
              apiGraphqlErrorTrap(err);
              break;
          }
        }
      }
      if (networkError) {
        switch (networkError.response && networkError.response.status) {
          case 403:
            handleLogout();
            break;
          default:
            //TODO: handle defualt network errors
            apiNetworkErrorTrap(networkError);
            break;
        }
      }
    }
  );

  const retry = new RetryLink({ attempts: { max: 3 } });

  const request = async (operation) => {
    const token = await getToken(ctx);
    if (token) {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  };

  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        let handle;
        Promise.resolve(operation)
          .then((oper) => request(oper))
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));

        return () => {
          if (handle) handle.unsubscribe();
        };
      })
  );

  const http = new HttpLink({
    uri: process.env.PAWLYTICS_API,
  });

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
      __schema: {
        types: [], // no types provided
      },
    },
  });

  const cache = new InMemoryCache({ fragmentMatcher });

  // The `ctx` (NextPageContext) will only be present on the server.
  // use it to extract auth headers (ctx.req) or similar.
  return new ApolloClient({
    ssrMode: Boolean(ctx),
    link: ApolloLink.from([retry, onError, requestLink, http]),
    cache,
  });
}
