import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: 1.0,
    blacklistUrls: [
      'http://localhost:3000/',
      'http://pawlytics.ngrok.io',
      'https://pawlytics.ngrok.io',
    ],
  });
}
