import { useEffect } from 'react';
import { AppProps } from 'next/app';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { withApollo } from 'lib/apollo';
import { ToastContainer, Flip } from 'react-toastify';
import { AppThemeProvider } from 'helpers/theme';
import { initializeMedian } from 'helpers/median';
import { Provider } from 'jotai';
import { PublicPageLayout } from 'components/layouts/public-page';
import { UserProvider } from 'state/UserContext';
import Head from 'next/head';
import 'react-toastify/dist/ReactToastify.css';

type Props = AppProps & {
  idToken: Object | undefined;
  hash: undefined;
  apolloClient: ApolloClient<any>;
};

function MyApp(props: Props) {
  const { Component, pageProps, hash, apolloClient } = props;

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (location.hostname.match('pawlytics.com')) {
      initializeMedian();
    }
  }, []);

  const publicRoutes = [
    '/login',
    '/logout',
    '/signup',
    '/accept-invite',
    '/reset-password',
  ];
  if (!!publicRoutes.find((route) => props.router.route.startsWith(route))) {
    return (
      <AppThemeProvider>
        <ApolloProvider client={apolloClient}>
          <Provider>
            <PublicPageLayout>
              <Head>
                <meta
                  name="viewport"
                  content="initial-scale=1.0, width=device-width"
                />
                <meta charSet="utf-8" />
              </Head>

              <Component {...pageProps} />
            </PublicPageLayout>
          </Provider>
          <ToastContainer
            pauseOnFocusLoss={false}
            position="bottom-left"
            closeOnClick
            draggable
            hideProgressBar
            autoClose={2500}
            transition={Flip}
          />
        </ApolloProvider>
      </AppThemeProvider>
    );
  }

  return (
    <AppThemeProvider>
      <ApolloProvider client={apolloClient}>
        <Provider>
          <UserProvider>
            <Head>
              <meta
                name="viewport"
                content="initial-scale=1.0, width=device-width"
              />
              <meta charSet="utf-8" />
            </Head>
            <Component {...pageProps} hash={hash} />
            <ToastContainer
              pauseOnFocusLoss={false}
              position="bottom-left"
              closeOnClick
              draggable
              hideProgressBar
              autoClose={2500}
              transition={Flip}
            />
          </UserProvider>
        </Provider>
      </ApolloProvider>
    </AppThemeProvider>
  );
}

export default withApollo({ ssr: true })(MyApp);
