import { ThemeProvider } from 'styled-components';
import * as colors from 'helpers/colors';
import * as sizes from 'helpers/sizes';

export const theme = {
  colors,
  sizes,
  ...sizes,
};

export function AppThemeProvider({ children }: { children: JSX.Element }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
