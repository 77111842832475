import { PropsWithChildren } from 'react';
import styled from 'styled-components';

const Background = styled.div`
  background: url('/woman-cat.jpg') center center no-repeat;
  background-size: cover;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  :before {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background-image: ${(p) =>
      `linear-gradient(0deg, ${p.theme.colors.purple}, ${p.theme.colors.pink})`};
    opacity: 0.8;
  }
`;

export function PublicPageLayout({ children }: PropsWithChildren<{}>) {
  return (
    <>
      <Background />
      {children}
    </>
  );
}
